import "./vision.css";

const VisionSection = () => {
  return (
    <div className="section" id="vision">
      <div className="container-fluid">
        <h1 className="vision-1 token-title mt-4">
          <span>RXG</span> <br /> TOKEN
        </h1>
        <div className="text-center px-1 vision-bottom">
          <div className="vision-2">
            <h5>OUR VISION</h5>
            <p>
              To build it and hope they come is a floored theory. The secret to
              onboarding the world to Web3? Don’t bring them to it - meet them
              where they are. The platform is a seamless continuum of
              interconnected tools and services, designed to support users at
              every stage of life’s journey. Everything you use, seamlessly
              housed in one intuitive platform.
            </p>
          </div>
          {/* <div className="vision-4">
            <button className="btn btn-outline-primary mt-2">Learn More</button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default VisionSection;
