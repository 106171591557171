import wallet from "../../../assets/images/ecosystem/wallet.png";
import social from "../../../assets/images/ecosystem/social.png";
import exchange from "../../../assets/images/ecosystem/exchange.png";
import guild from "../../../assets/images/ecosystem/guild.png";
import h2x from "../../../assets/images/ecosystem/h2x.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ecosystem.css";

const EcoSection = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: "30px",
  };

  return (
    <div className="section d-flex flex-column" id="ecosystem">
      <div className="ecosystem-container text-white">
        <div className="container-fluid">
          <div className="d-flex align-items-center">
            <div className="text-start flex-grow-1">
              <h1 className="utility-title">Utility Token</h1>
              <p className="utility-subtitle">
                With RXG, you get access to a world where blockchain works
                behind the scenes to make life simpler, smarter, and safer:
              </p>
            </div>
            <div className="text-center">
              <h1>10</h1>
              <div>
                <h4 className="mt-0">Billion RXG tokens will be minted</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ecosystem-notify">
        <h4>NO NEW TOKENS CREATED IN THE FUTURE</h4>
      </div>
      <div className="eco-sub-block">
        <div className="ecosystem-title-block position-relative">
          {/*  <h1 className="ecosystem-title-xx">REXLA</h1> */}
          <h1 className="ecosystem-title-x">The Continuum</h1>
          <p className="ecosystem-title-sub">Experience the Rexla Continuum</p>
        </div>
        <div className="section-carousel">
          <Slider {...settings}>
            <div className="eco-section">
              <img src={exchange} alt="exchange" />
              <h4>REXLA EXCHANGE</h4>
              {/* <h3>Trading Platform</h3> */}
              <p>
                A low-cost, secure platform to trade, swap, and manage digital
                assets.
              </p>
            </div>
            <div className="eco-section">
              <img src={wallet} alt="wallet" />
              <h4 className="text-white">REXLA WALLET & PROTECT</h4>
              {/* <h3>Secure Digital Wallet</h3> */}
              <p>
                Manage your crypto safely and designate guardians for asset
                recovery.
              </p>
            </div>
            <div className="eco-section">
              <img src={guild} alt="guild" />
              <h4>REXLA ACADEMY</h4>
              {/* <h3>Educational Hub</h3> */}
              <p>Advance your Web3 knowledge with world-class education.</p>
            </div>

            <div className="eco-section">
              <img src={h2x} alt="H2X" />
              <h4>H2X WATER</h4>
              {/* <h3>Educational Hub</h3> */}
              <p>
                Enjoy blockchain-tracked premium bottled water with a commitment
                to sustainability.
              </p>
            </div>

            <div className="eco-section">
              <img src={social} alt="social" />
              <h4>REXLA SOCIAL</h4>
              {/* <h3>Blockchain Assurance</h3> */}
              <p>
                Connect, share, and thrive in a crypto-focused social media
                platform.
              </p>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default EcoSection;
