import {
  simulateContract,
  waitForTransactionReceipt,
  writeContract,
} from "@wagmi/core";
import { config } from "../config";
import RXGTokenSale from "./abi/RXGTokenSale.json";
import { parseEther } from "viem";
import showSwal from "../utils/showSwal";
import { ethers } from "ethers";

export const buyTokens = async (price) => {
  try {
    const { request } = await simulateContract(config, {
      abi: RXGTokenSale.abi,
      address: process.env.REACT_APP_SALE_CONTRACT_ADDRESS,
      functionName: "buyTokens",
      args: [],
      value: parseEther(price.toString()),
    });

    const hash = await writeContract(config, request);
    await waitForTransactionReceipt(config, {
      hash,
    });

    const tokens = price / process.env.REACT_APP_PRICE;
    showSwal(`${tokens} RXG tokens are minted!`, "success");
    return { sucess: true, hash };
  } catch (err) {
    console.log(err);
    /* if (err.code !== 4001)
      showSwal(err?.error?.message || err.message, "warning"); */
    return;
  }
};

export const buyTokensMagic = async (data, provider) => {
  try {
    if (!provider) {
      throw new Error("Magic provider instance not found");
    }

    const signer = provider.getSigner();
    const contractAddress = process.env.REACT_APP_SALE_CONTRACT_ADDRESS;
    const contract = new ethers.Contract(
      contractAddress,
      RXGTokenSale.abi,
      signer
    );

    // Extract relevant values from `data`
    const tokenAmount = data.tokenAmount; // Number of tokens to purchase (smallest units)
    const payTokenAddress = data.payToken.address; // Payment token address (e.g., USDT)
    const timestamp = data.timestamp; // Timestamp for signature
    const signature = data.signature; // Signature for verification

    // Create a contract instance for the payment token
    const ERC20_ABI = [
      "function approve(address spender, uint256 amount) external returns (bool)",
    ];
    const paymentTokenContract = new ethers.Contract(
      payTokenAddress,
      ERC20_ABI,
      signer
    );

    // Step 1: Approve the contract to spend tokens
    const approveTx = await paymentTokenContract.approve(
      contractAddress,
      tokenAmount
    );
    console.log("Approval transaction hash:", approveTx.hash);
    await approveTx.wait();

    // Call the contract's buyTokensByToken function
    const tx = await contract.buyTokens(
      payTokenAddress,
      tokenAmount,
      timestamp,
      signature
    );

    const receipt = await tx.wait();

    const tokens = ethers.utils.formatUnits(tokenAmount, 6); // Assuming RXG has 6 decimals

    return { success: true, hash: receipt.transactionHash, tokens };
  } catch (err) {
    if (err.code != -32603)
      showSwal(
        err.message || err.error || "An error occurred while buying tokens",
        "warning"
      );
    return { success: false, error: err.error };
  }
};
