import React from "react";
import "./education.css"; // Custom CSS for styling

const EducationComp = () => {
  return (
    <div className="education">
      {/* Progress Bar Section */}
      <h5 className="text-center">Rexla Guild Progress</h5>
      <p className="level-text text-center">Level 0</p>
      <div className="progress-container">
        <div className="progress-bar">
          <div className="progress-fill" style={{ width: "0%" }}>
            <span>Level 0</span>
          </div>
          <span className="next-level">Level 8</span>
        </div>
      </div>

      {/* View All Sessions */}
      <div className="text-center">
        <a href="#sessions" className="view-sessions-link">
          View all sessions
        </a>
      </div>

      {/* Badges Section */}
      <div className="badges-area mt-4">
        <p>BADGES AREA?</p>
        <p>NO BADGES PROVIDED</p>
      </div>
    </div>
  );
};

export default EducationComp;
