import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Countdown from "../../../components/countdown";
// import { useAccount, useBalance } from "wagmi";
import { buyTokensMagic } from "../../../web3/buyToken";
import Swal from "sweetalert2/src/sweetalert2.js";
import WalletConnectButton from "../../../components/WalletConnectButton";
import { useMagic } from "../../../hooks/MagicProvider";
import { checkBuyable, getBuyData, getPayTokens } from "../../../api";
import "./join.css";

const JoinSection = ({ isMobile }) => {
  const navigate = useNavigate();
  // const { address, isConnected } = useAccount();
  const { isLoggedIn, provider, userInfo, isLoading, getTokenBalance } =
    useMagic();

  const [isInsufficient, setIsInsufficient] = useState(false);
  const [isInsufficientTokens, setIsInsufficientTokens] = useState(false);
  const [loading, setLoading] = useState(false);

  const [payTokens, setPayTokens] = useState([]);
  const [selPayTokenIndex, setSelPayTokenIndex] = useState(0);
  const [price, setPrice] = useState(0);
  const [totalToken, setTotalToken] = useState(0);
  const [rxgAmount, setRxgAmount] = useState(0);
  const [payTokenAmount, setPayTokenAmount] = useState(0);
  const [errMsg, setErrMsg] = useState("");
  const [disabled, setDisabled] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const fetchPayTokens = async () => {
      setLoading(true);
      const tokens = await getPayTokens();
      setLoading(false);
      setPayTokens(tokens);
    };
    fetchPayTokens();
  }, []);

  const fetchCheckBuyable = async () => {
    const checkData = await checkBuyable();
    if (checkData.success === true) {
      setPrice(checkData.price);
      setTotalToken(checkData.available);
    } else {
      setErrMsg(checkData.message);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchCheckBuyable();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const fetchData = async () => {
      let payAmount = (price * rxgAmount).toFixed(3);
      setPayTokenAmount(payAmount);
      if (rxgAmount > totalToken) setIsInsufficientTokens(true);
      else setIsInsufficientTokens(false);
      /* setIsInsufficient(payTokenAmount > balance?.formatted); */
      if (userInfo && payTokens.length > 0) {
        const tokenBalance = await getTokenBalance(
          payTokens[selPayTokenIndex]?.address,
          userInfo?.address
        );
        if (payAmount > 0 && tokenBalance < payAmount) setIsInsufficient(true);
        else setIsInsufficient(false);
      }
    };

    fetchData();
  }, [rxgAmount, selPayTokenIndex, userInfo, payTokenAmount]);

  useEffect(() => {
    setDisabled(!isLoggedIn || loading || isLoading);
  }, [isLoggedIn, loading, isLoading, rxgAmount]);

  const handleBuyToken = async () => {
    setLoading(true);
    const data = await getBuyData({
      count: rxgAmount,
      payTokenAddress: payTokens[selPayTokenIndex].address,
    });

    if (!data.success) {
      setErrMsg(data.message);
    } else {
      const result = await buyTokensMagic(data, provider);
      if (result.success) {
        Swal.fire({
          title: "Welcome to RXG!",
          text: `${result.tokens} RXG tokens are minted!`,
          icon: "success",
        });
        setRxgAmount(0);
      }
      fetchCheckBuyable();
    }
    setLoading(false);
  };

  return (
    <div className="section" id="join">
      <div className="container-fluid">
        <div className="join-top d-flex flex-column align-items-center">
          {isMobile ? (
            <>
              <h1 className="join-title-xx">Join</h1>
              <h1 className="join-title">the Movement</h1>
            </>
          ) : (
            <h1 className="join-title">Join the Movement</h1>
          )}

          <p>
            All the benefits. Zero effort. RXG makes it easy to step into the
            future of blockchain, empowering you with tools that work for you,
            not against you.
          </p>
          <p>
            Be a part of the Web3 revolution with RXG and Rexla. Together, we’ll
            redefine the possibilities of blockchain.
          </p>
        </div>

        <div className="btn-group">
          {location.pathname === "/ico" && (
            <div className="mt-2">
              <Countdown
                targetDate={parseInt(process.env.REACT_APP_TARGET_DATE)}
              />
            </div>
          )}
          <div className="d-flex justify-content-center gap-3 w-100 mt-5">
            {isLoggedIn && userInfo && !userInfo.is_verified ? (
              <button
                className="btn btn-primary flex-1"
                onClick={() => navigate("/onboarding/access")}
              >
                GO TO ONBOARDING
              </button>
            ) : (
              <>
                {!isMobile && (
                  <WalletConnectButton className="btn btn-outline-primary" />
                )}
                <button
                  className="btn btn-primary flex-1 gap-1 d-flex align-items-center"
                  onClick={() => handleBuyToken()}
                  disabled={
                    disabled ||
                    rxgAmount <= 0 ||
                    isInsufficientTokens ||
                    isInsufficient
                  }
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  BUY RXG TOKEN
                </button>
              </>
            )}
          </div>

          <div className="mt-4">
            <h2>1 RXG = {process.env.REACT_APP_PRICE} USD</h2>
          </div>

          <div className="rxg-form d-flex align-items-baseline justify-content-center gap-3 mt-4">
            <div className="text-start">
              <label className="text-start">RXG you receive</label>
              <input
                className="form-control"
                value={rxgAmount}
                onChange={(e) => setRxgAmount(e.target.value)}
                type="number"
                disabled={disabled}
              />
              <p className="notify">
                {isInsufficientTokens &&
                  "This amount exceeds the available supply"}
              </p>
            </div>
            <div className="text-start">
              <label className="text-start">Payment token</label>
              <select
                className="form-select"
                onChange={(e) => setSelPayTokenIndex(e.target.value)}
                value={selPayTokenIndex}
                disabled={disabled}
              >
                {payTokens?.map((token, index) => {
                  return (
                    <option value={index} key={index}>
                      {token.symbol}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="text-start">
              <label className="text-start">USD you pay</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={payTokenAmount}
                  onChange={(e) => setPayTokenAmount(e.target.value)}
                  readOnly
                />

                {/* <div className="input-group-append">
                  <span className="input-group-text">$</span>
                </div> */}
              </div>
              <p className="notify">
                {isInsufficient && "Insufficient funds!"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinSection;
