import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import simplify_icon_1 from "../../../assets/images/landing/simplify-icon-1.png";
import simplify_icon_2 from "../../../assets/images/landing/simplify-icon-2.png";
import simplify_icon_3 from "../../../assets/images/landing/simplify-icon-3.png";
import globe from "../../../assets/images/landing/global-bg.png";
import platform_bg from "../../../assets/images/landing/footer-bg.png";
import card_icon1 from "../../../assets/images/landing/security.png";
import card_icon2 from "../../../assets/images/landing/transparency.png";
import card_icon3 from "../../../assets/images/landing/innovation.png";
import "./foundation.css";
import { useNavigate } from "react-router-dom";
/* import { Link } from "react-router-dom"; */

gsap.registerPlugin(ScrollTrigger);

const Foundation = () => {
  const navigate = useNavigate();
  useEffect(() => {
    // Split text animations
    const titleSplit = new SplitType(".foundation-title", { types: "words" });
    const cardTitleSplit = new SplitType(".feature-card .card-title", {
      types: "words",
    });

    const splitGlobeText = new SplitType(".globe-content h3", {
      types: "words",
    });

    const spliteText = new SplitType(".bottom-text", {
      types: "words",
    });

    // Unified animation timeline
    const foundationTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: ".foundation",
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    foundationTimeline
      // Foundation title animation
      .fromTo(
        titleSplit.chars,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.05,
          ease: "power3.out",
        }
      )
      // Simplify block animation
      .fromTo(
        ".simplify-block .d-flex",
        { opacity: 0, y: 30 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          ease: "power3.out",
        },
        "-=0.5"
      )
      .fromTo(
        ".globe-img",
        { opacity: 0, scale: 0.5 },
        {
          opacity: 1,
          scale: 1,
          duration: 2.5,
          ease: "power3.out",
          zIndex: 0,
        }
      )
      .fromTo(
        splitGlobeText.words,
        { opacity: 0, y: 30 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.1,
          ease: "power3.out",
          zIndex: 1,
        },
        "-=0.8"
      )
      .fromTo(
        ".globe-content .btn-primary",
        { opacity: 0, y: 30 },
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          ease: "power3.out",
          zIndex: 1,
        },
        "-=0.4"
      )
      // Feature cards animation
      .fromTo(
        ".feature-card",
        { opacity: 0, scale: 0.9, y: 30 },
        {
          opacity: 1,
          scale: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          ease: "power3.out",
        }
      )
      .fromTo(
        cardTitleSplit.words,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          stagger: 0.1,
          ease: "power3.out",
        },
        "-=0.5"
      )

      // Platform animation
      .fromTo(
        ".platform-content h1, .platform-content h4",
        { opacity: 0, y: -80 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          ease: "power3.out",
        },
        "+=1.2"
      )
      .fromTo(
        spliteText.words,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.1,
          ease: "power3.out",
        }
      )
      // Bottom block animation
      .fromTo(
        ".bottom-block h2, .bottom-block button",
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          ease: "power3.out",
        }
      );

    // Clean up animations on component unmount
    return () => {
      foundationTimeline.kill();
    };
  }, []);

  return (
    <div className="foundation">
      <div className="container">
        <h1 className="foundation-title mb-5">
          <span>Simplify</span> your digital life
        </h1>

        <div className="simplify-block">
          <div className="d-flex gap-5 align-items-center">
            <img src={simplify_icon_1} alt="platform" className="img-fluid" />
            <div className="text-start d-flex aligm-items-center flex-column justify-content-center">
              <h4 className="text-light">One Platform, Many Solutions:</h4>
              <p>
                No more juggling multiple apps; everything you need is right
                here.
              </p>
            </div>
          </div>
          <div className="d-flex gap-5 mt-5 align-items-center">
            <img src={simplify_icon_2} alt="platform" className="img-fluid" />
            <div className="text-start d-flex aligm-items-center flex-column justify-content-center">
              <h4 className="text-light">User-Friendly Design:</h4>
              <p>
                Intuitive interface for ease of use, regardless of technical
                background.
              </p>
            </div>
          </div>
          <div className="d-flex gap-5 mt-5 align-items-center">
            <img src={simplify_icon_3} alt="platform" className="img-fluid" />
            <div className="text-start d-flex aligm-items-center flex-column justify-content-center">
              <h4 className="text-light">Build Confidence:</h4>
              <p>
                AI-driven learning tools tailored to individual learning
                journeys.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="globe-block">
        <div className="globe-content">
          <h3 className="text-light">
            Join a global community focused on positive change and empowerment.
            Support global initiatives, and make a real impact.
          </h3>
          <a
            className="btn btn-primary mt-4"
            href="https://rexla.com/sign-up/"
            target="_blank"
          >
            Waiting List
          </a>
        </div>
        <img className="img-fluid globe-img" src={globe} alt="globe" />
      </div>

      <div className="livello-section">
        <div className="container">
          <div className="row justify-content-center mt-5">
            <div className="col-lg-4 col-md-6 mb-2">
              <div className="card feature-card text-center">
                <div className="card-body">
                  <div className="feature-icon mb-4">
                    <img
                      src={card_icon1}
                      alt="Credibility Icon"
                      className="img-fluid"
                    />
                  </div>
                  <h5 className="card-title">Security & Compliance</h5>
                  <p className="card-text">
                    Enterprise-grade security measures and adherence to global
                    standards.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-2">
              <div className="card feature-card text-center">
                <div className="card-body">
                  <div className="feature-icon mb-4">
                    <img
                      src={card_icon2}
                      alt="Compliance Icon"
                      className="img-fluid"
                    />
                  </div>
                  <h5 className="card-title">Transparency</h5>
                  <p className="card-text">
                    Open communication and honesty in all our operations.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-2">
              <div className="card feature-card text-center">
                <div className="card-body">
                  <div className="feature-icon mb-4">
                    <img
                      src={card_icon3}
                      alt="Security Icon"
                      className="img-fluid"
                    />
                  </div>
                  <h5 className="card-title">Innovation</h5>
                  <p className="card-text">
                    Continuous improvement to bring you the best in technology.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="platform position-relative">
        <img src={platform_bg} alt="bridge" className="w-100" />
        <div className="platform-content">
          <h1>Rexla Global is more than a platform.</h1>
          <h4 className="text-light">
            It’s a revolution in how we interact with technology.
          </h4>
          <h4 className="text-light">
            By bringing together all your essential services into one accessible
            place, we simplify your digital life and empower you to unlock your
            full potential, ensuring nobody feels left behind.
          </h4>
        </div>
      </div>
      <div className="bottom-block px-3">
        <h2>
          Start your journey towards an empowered, simplified, and rewarding
          digital life with Rexla Global
        </h2>
        <button
          className="btn btn-primary mt-5 mb-5"
          onClick={() => navigate("/onboarding")}
        >
          Join now
        </button>

        <p style={{ maxWidth: "600px" }} className="m-auto bottom-text">
          Start your journey towards an empowered, simplified, and rewarding
          digital life with Rexla Global
        </p>
      </div>
      <div className="container form-block mt-5 pb-4">
        <div
          className="elfsight-app-96784cab-c053-4158-a46a-69065634238e"
          data-elfsight-app-lazy
        ></div>
      </div>
    </div>
  );
};

export default Foundation;
