import { useEffect } from "react";
import "./about.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
import me from "../../../assets/images/landing/about-bg1.jpg";
gsap.registerPlugin(ScrollTrigger);

const About = () => {
  useEffect(() => {
    // Split the heading text into individual words
    const headingText = new SplitType(".about h1", {
      types: "words, chars",
    });

    // Create a timeline
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".about",
        start: "top 60%",
        toggleActions: "play none none reverse",
      },
    });

    // Add animations to the timeline
    tl.fromTo(
      headingText.words,
      { y: 50, opacity: 0, scale: 0.95 },
      {
        y: 0,
        opacity: 1,
        scale: 1,
        duration: 1.2,
        ease: "power3.out",
        stagger: 0.2,
      }
    )
      .fromTo(
        ".about .section-detail",
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power3.out",
        },
        "-=0.5" // Start 0.5 seconds before the previous animation ends
      )
      .fromTo(
        ".about .sub-description",
        { opacity: 0, y: 20, scale: 0.9 },
        {
          opacity: 1,
          y: 0,
          scale: 1,
          duration: 1.2,
          zIndex: 1,
          ease: "power3.out",
        },
        "-=0.5" // Start 0.5 seconds before the previous animation ends
      )
      .fromTo(
        ".about .me-img",
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 1.2,
          ease: "power3.out",
        },
        "-=0.5" // Start 0.5 seconds before the previous animation ends
      );

    // Clean up function
    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div className="about">
      <div className="container-fluid">
        <h1 className="text-dark section-title">
          <span>Welcome to Rexla Global</span>
        </h1>
        <div className="container">
          <p className="text-white section-detail mt-3">
            A transformative digital platform that unites all your
            essentialservices under one roof. <br />
            Connecting the dots across social media, finance,
            shopping,education, and more, we make managing your digital life
            eﬀortless.
          </p>
        </div>
      </div>
      <div className="about-sub-section">
        <h2 className="text-white sub-description">
          Our platform is designed for YOU, empowering your journey with ease,
          confidence, and a seamless experience.
        </h2>
        <img src={me} alt="me" className="w-100 me-img" />
      </div>
    </div>
  );
};

export default About;
