import React from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
/* import { useParams, useNavigate } from "react-router-dom"; */

const ProgressB = ({ currentStep, totalSteps }) => {
  const steps = [
    { name: "Access" },
    { name: "KYC" },
    { name: "Add&nbsp;Funds" },
    { name: "RXG" },
  ];

  // Ensure we don't divide by zero and limit the percent to 100
  const percent = Math.floor(
    Math.min((currentStep / (totalSteps - 1)) * 100, 100)
  );

  return (
    <ProgressBar filledBackground="#fff" percent={percent}>
      {steps.map((step, index) => (
        <Step key={index} transition="scale">
          {({ accomplished }) => (
            <>
              <div
                className={`transitionStep ${
                  index === currentStep ? "current" : ""
                } ${index < currentStep ? "accomplished" : "null"}`}
              >
                {index < currentStep && <i className="fa fa-check" />}
                <p dangerouslySetInnerHTML={{ __html: step.name }} />
              </div>
            </>
          )}
        </Step>
      ))}
    </ProgressBar>
  );
};

export default ProgressB;
