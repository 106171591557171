import React, { useState, useEffect, useRef, lazy, Suspense } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import "./onboarding.css";
import "react-step-progress-bar/styles.css";
import ProgressB from "../../components/ProgressBar";
import { useMagic } from "../../hooks/MagicProvider";

const Onboarding = () => {
  const [direction, setDirection] = useState("forward");
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const currentQueryParams = location.search;
  const { step } = useParams();
  const { isLoading, userInfo, isLoggedIn } = useMagic();

  /* useEffect(() => {
    const verifyUser = async () => {
      if (!isLoading) {
        if (!userMetadata) {
          navigate(`/onboarding/access${currentQueryParams}`);
          return;
        }

        try {
          if (!userInfo) {
            navigate(`/onboarding/access${currentQueryParams}`);
          }
        } catch (error) {
          console.error("Error verifying user:", error);
          navigate(`/onboarding/access${currentQueryParams}`);
        }
      }
    };

    verifyUser();
  }, [userMetadata, navigate, isLoading]); */

  useEffect(() => {
    if (!isLoading && !isLoggedIn && step !== "access")
      navigate(`/onboarding/access`);

    if (
      !isLoading &&
      userInfo &&
      (step === "funds" || step === "rxg") &&
      !userInfo.is_verified
    )
      navigate(`/onboarding/access`);
  }, [step, isLoggedIn, userInfo, isLoading, navigate]);

  // Map step names to dynamic imports
  const sections = {
    access: lazy(() => import("../../sections/Onboarding/Access")),
    kyc: lazy(() => import("../../sections/Onboarding/KYC")),
    funds: lazy(() => import("../../sections/Onboarding/Funds")),
    rxg: lazy(() => import("../../sections/Onboarding/RXG")),
  };

  // Get the current step index and component based on the URL parameter
  const currentSectionName = step || "access";
  const CurrentSectionComponent =
    sections[currentSectionName] || sections.access;

  const nextStep = async () => {
    const sectionKeys = Object.keys(sections);
    const currentStepIndex = sectionKeys.indexOf(currentSectionName);
    if (currentStepIndex < sectionKeys.length - 1) {
      setDirection("forward");
      const nextSection = sectionKeys[currentStepIndex + 1];
      navigate(`/onboarding/${nextSection}${currentQueryParams}`);
    }
  };

  const prevStep = () => {
    const sectionKeys = Object.keys(sections);
    const currentStepIndex = sectionKeys.indexOf(currentSectionName);
    if (currentStepIndex > 0) {
      setDirection("backward");
      const prevSection = sectionKeys[currentStepIndex - 1];
      navigate(`/onboarding/${prevSection}${currentQueryParams}`);
    }
  };

  useEffect(() => {
    const updateContainerSize = () => {
      if (containerRef.current) {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        const containerTop = containerRef.current.getBoundingClientRect().top;
        const paddingOffset = viewportHeight * 0.04;

        if (isMobile) {
          const newWidth = containerRef.current.offsetWidth * 0.96;
          const newHeight = viewportHeight - containerTop;
          setContainerSize({ width: newWidth, height: newHeight });
        } else {
          const newHeight = viewportHeight - containerTop - paddingOffset;
          setContainerSize({ width: viewportWidth, height: newHeight });
        }
      }
    };

    const checkIfMobile = () => {
      const newIsMobile =
        window.innerWidth <= 768 || window.innerWidth < window.innerHeight;
      setIsMobile(newIsMobile);
    };

    checkIfMobile();
    updateContainerSize();

    window.addEventListener("resize", checkIfMobile);
    window.addEventListener("resize", updateContainerSize);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
      window.removeEventListener("resize", updateContainerSize);
    };
  }, [isMobile]);

  return (
    <div id="onboarding" className="onboarding">
      <div className="container-fluid">
        <div className="progressBarContainer">
          <ProgressB
            currentStep={Object.keys(sections).indexOf(currentSectionName)}
            totalSteps={Object.keys(sections).length}
          />
        </div>
        <div
          className={`onboarding-container ${isMobile ? "mobile" : ""} ${
            step === "kyc" ? "kyc-container" : ""
          }`}
          ref={containerRef}
          style={{ height: `${containerSize.height}px` }}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <div
              className={`section-wrapper active ${
                direction === "forward" ? "slide-forward" : "slide-backward"
              }`}
            >
              <CurrentSectionComponent
                onNext={nextStep}
                onPrev={prevStep}
                isActive={true}
                containerWidth={containerSize.width}
                containerHeight={containerSize.height - 10}
                isMobile={isMobile}
              />
            </div>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
