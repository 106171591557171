import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import Hero from "../../sections/Landing/hero";
import About from "../../sections/Landing/about";
import Mission from "../../sections/Landing/mission";
import Bullets from "../../sections/Landing/bullets";
import Projects from "../../sections/Landing/projects";
import RealWorld from "../../sections/Landing/realworld";
import Timeline from "../../sections/Landing/timeline";
import Foundation from "../../sections/Landing/foundation";
import "./landing.css";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const Landing = () => {
  const smoothWrapper = useRef(null);
  const smoothContent = useRef(null);

  useEffect(() => {
    // Create the smooth scroller
    let smoother = ScrollSmoother.create({
      wrapper: smoothWrapper.current,
      content: smoothContent.current,
      smooth: 3, // how long (in seconds) it takes to "catch up" to the native scroll position
      effects: true, // looks for data-speed and data-lag attributes on elements
      smoothTouch: 0.1, // much shorter smooth time on touch devices (default is NO smooth on touch devices)
    });

    // Optional: Add parallax effects to some elements
    gsap.utils.toArray(".parallax").forEach((element) => {
      const depth = element.dataset.speed || 0.1;
      const movement = -(element.offsetHeight * depth);
      gsap.fromTo(
        element,
        { y: 0 },
        {
          y: movement,
          ease: "none",
          scrollTrigger: {
            trigger: element,
            start: "top bottom",
            end: "bottom top",
            scrub: true,
          },
        }
      );
    });

    // Clean up function
    return () => {
      if (smoother) {
        smoother.kill();
      }
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <div ref={smoothWrapper} id="smooth-wrapper">
      <div ref={smoothContent} className="landing" id="smooth-content">
        <Hero />
        <About />
        <Mission />
        <Bullets />
        {/* <Everyone /> */}
        {/* <Projects /> */}
        {/* <RealWorld /> */}
        {/* <Timeline /> */}
        <Foundation />
      </div>
    </div>
  );
};

export default Landing;
