import rexlaAxios from "./api";
import showSwal from "../utils/showSwal";

export const getKycURL = async ({ reVerify }) => {
  try {
    const response = await rexlaAxios.post("getKycURL", { reVerify });
    if (response.data && response.data.success === true) {
      return response.data.data;
    }
    return;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};

export const getKycVerification = async ({ verification_id }) => {
  try {
    const response = await rexlaAxios.post("getKycVerification", {
      verification_id,
    });
    if (response.data && response.data.success === true) {
      return response.data.data;
    }
    return;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};
