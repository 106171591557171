import axios from "axios";
import { Magic as MagicBase } from "magic-sdk";

const rexlaAxios = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL, //YOUR_API_URL HERE
  /* withCredentials: true, */
  headers: {
    "Content-Type": "application/json",
  },
});

// Add an interceptor to include the Magic ID token in requests
rexlaAxios.interceptors.request.use(
  async (config) => {
    const magic = new MagicBase(process.env.REACT_APP_MAGIC_API_KEY);
    const isLoggedIn = await magic.user.isLoggedIn();
    if (isLoggedIn) {
      const idToken = await magic.user.getIdToken();
      if (idToken) {
        config.headers.Authorization = `Bearer ${idToken}`;
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default rexlaAxios;
