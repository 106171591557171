import rexlaAxios from "./api";
import showSwal from "../utils/showSwal";

export const enrollAmbassador = async () => {
  try {
    const response = await rexlaAxios.post("enrollAmbassador");
    if (response.data && response.data.success === true) {
      return response.data;
    }
    return;
  } catch (err) {
    showSwal(err?.response?.data?.message || err.message, "error");
    return;
  }
};

export const enrollAmbassadorWithShortCode = async ({ short_code }) => {
  try {
    const response = await rexlaAxios.post("enrollAmbassadorWithShortCode", {
      short_code,
    });
    if (response.data && response.data.success === true) {
      return response.data;
    }
    return;
  } catch (err) {
    showSwal(err?.response?.data?.message || err.message, "error");
    return;
  }
};
