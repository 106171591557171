import "./home-mobile.css";
import HeroSection from "../../sections/HomeMobile/hero";
import VisionSection from "../../sections/HomeMobile/vision";
import InfoSection from "../../sections/HomeMobile/info";
import UtilitySection from "../../sections/HomeMobile/utility";
import EcoSection from "../../sections/HomeMobile/ecosystem";
import RoadmapSection from "../../sections/HomeMobile/roadmap";
import JoinSection from "../../sections/Home/join";

import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import WalletConnectButton from "../../components/WalletConnectButton";
import { Navbar } from "../../components/navbar";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

const HomeMobile = () => {
  const canvasRef = useRef(null);
  const images = useRef([]);
  const screens = useRef({ frame: 0 });

  const frameCount = 580 + 230;
  const currentFrame = (index) =>
    `./assets/images/mobile/${index.toString().padStart(4, "0")}.png`;

  useEffect(() => {
    const parentElement = document.getElementById("home_mobile");
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const setCanvasSize = () => {
      canvas.width = parentElement.clientWidth;
      canvas.height = parentElement.clientWidth * 1.77777;
      /* } */
    };

    setCanvasSize();

    for (let i = 0; i < frameCount; i++) {
      const img = new Image();
      if (i > 470 && i < 700) img.src = currentFrame(470);
      else if (i >= 700) img.src = currentFrame(i - 230);
      else img.src = currentFrame(i);
      images.current.push(img);
    }

    let canvasTimeline = gsap.timeline();

    canvasTimeline.to(screens.current, {
      frame: frameCount - 1,
      snap: "frame",
      ease: "none",
      scrollTrigger: {
        scrub: 4,
      },
      delay: 1,
      duration: 100000,
      onUpdate: () => {
        /* if (screens.current.frame > 610 && screens.current.frame < 720) {
          canvas.width = parentElement.clientWidth / 2.5;
          canvas.height = (parentElement.clientWidth / 1.777 - 65) / 2.5;
        } else  */ setCanvasSize();
        render();
      },
    });

    images.current[0].onload = render;

    function render() {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(
        images.current[screens.current.frame],
        0,
        0,
        canvas.width,
        canvas.height
      );
    }

    const handleResize = () => {
      setCanvasSize();
      render();
    };

    window.addEventListener("resize", handleResize);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "#home_mobile",
        start: "top top",
        end: "bottom bottom",
        scrub: 4, // Adjust this value for smoothness
        toggleActions: "play reverse play reverse",
      },
    });

    const hero = document.getElementById("hero");
    const vision = document.getElementById("vision");
    const info = document.getElementById("info");
    const utility = document.getElementById("utility");
    const ecosystem = document.getElementById("ecosystem");
    const roadmap = document.getElementById("roadmap");
    const join = document.getElementById("join");

    const roadmapHeight = roadmap.clientHeight;

    // Hero
    //gsap.fromTo(hero, { y: 400, opacity: 0 }, { y: 0, opacity: 1 });
    gsap.fromTo(canvasRef, { scale: 0 }, { scale: 1 });
    gsap.set(hero, { visibility: "visible" });
    tl.fromTo(hero, { y: 0 }, { y: 0 }, "+=3");
    tl.fromTo(".hero-text", { opacity: 1 }, { opacity: 0, duration: 1.5 });
    tl.fromTo(
      ".hero-title",
      { y: 0, opacity: 1 },
      { y: -50, opacity: 0, duration: 2 }
    );
    tl.set(hero, { visibility: "hidden" });

    // Vision
    tl.set(vision, { visibility: "visible" });
    tl.fromTo(
      ".vision-1",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1, duration: 1.5 }
    );
    tl.set("#canvas_main", {
      bottom: "50%",
      left: "50%",
      translateX: "-50%",
      translateY: "50%",
      duration: 2,
      height:
        window.innerHeight < canvas.height ? window.innerHeight : canvas.height,
    });
    tl.fromTo(".vision-2", { opacity: 0 }, { opacity: 1 });
    /* tl.fromTo(".vision-3", { opacity: 0 }, { opacity: 1 }); 
    tl.fromTo(".vision-4", { opacity: 0 }, { opacity: 1 });*/
    tl.to(vision, { y: 0 }, "+=1.5");
    tl.fromTo(vision, { opacity: 1 }, { opacity: 0, duration: 2 });
    tl.set(vision, { visibility: "hidden" });

    // Info
    tl.set(info, { visibility: "visible" });
    tl.set(".info-circle", { opacity: 0 });
    tl.fromTo(".main-circle", { opacity: 0 }, { opacity: 1, duration: 1.5 });
    tl.fromTo(
      ".info-circle",
      { opacity: 0 },
      { opacity: 1, duration: 2 },
      "+=1"
    );
    tl.to(info, { y: 0 }, "+=1");
    tl.fromTo(".info-circle", { opacity: 1 }, { opacity: 0 });
    tl.fromTo(".main-circle", { opacity: 1 }, { opacity: 0 });
    tl.fromTo(info, { opacity: 1 }, { opacity: 0 });
    tl.set(info, { visibility: "hidden" });

    // Utility
    tl.set("#canvas_main", { zIndex: 0 });
    if (window.innerWidth <= 768) tl.set("#canvas_main", { scale: 0.5 });
    tl.set(utility, { visibility: "visible" });
    tl.fromTo(
      ".benefit-block",
      { scaleY: 0, translateY: "50%" },
      { scaleY: 1, translateY: 0, duration: 1 }
    );
    tl.fromTo(
      ".utility-block",
      { scaleY: 0, translateY: "-50%" },
      { scaleY: 1, translateY: 0, duration: 1 },
      "-=1"
    );

    tl.fromTo(".utility-1", { opacity: 0 }, { opacity: 1, duration: 1 });
    /* tl.fromTo(
      ".utility-2",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1, duration: 1 }
    ); */
    tl.to(utility, { x: 0 }, "+=1");
    /* tl.to(".utility-2", { opacity: 0, duration: 1 }); */
    tl.to(".utility-1", { opacity: 0, duration: 1 });
    tl.fromTo(
      ".utility-block",
      { scaleY: 1, translateY: "0%" },
      { scaleY: 0, translateY: "50%", duration: 1 }
    );
    tl.to(
      ".benefit-block",
      { scaleY: 0, translateY: "-50%", duration: 1 },
      "-=1"
    );
    tl.set(utility, { visibility: "hidden" });
    tl.set("#canvas_main", { zIndex: -1, scale: 1 });
    // Ecosystem
    tl.set(ecosystem, { visibility: "visible" });
    tl.fromTo(
      ".ecosystem-container",
      { opacity: 0 },
      { opacity: 1, duration: 1, ease: "power2.out" }
    );
    tl.fromTo(
      ".ecosystem-container .container-fluid",
      { opacity: 0 },
      { opacity: 1, ease: "power2.out" }
    );

    tl.fromTo(
      ".ecosystem-title-block",
      { y: 50, opacity: 0 },
      { y: 0, opacity: 1 },
      "<"
    );

    // Horizontal scroll animation for eco-sections
    tl.to(ecosystem, { y: 0 }, "+=2");

    tl.to(".ecosystem-title-block", { opacity: 0 }, "<");
    tl.to(ecosystem, { opacity: 0 });
    tl.set(ecosystem, { visibility: "hidden" });

    // Roadmap
    tl.set(roadmap, { visibility: "visible" });
    tl.fromTo("#roadmap .roadmap-1", { y: 100 }, { y: 0 });

    tl.fromTo(".timeline-1 .timeline-badge", { scale: 0 }, { scale: 1 });
    tl.fromTo(".timeline", { opacity: 0 }, { opacity: 1 });
    /* tl.fromTo(".timeline-1 .date", { scaleX: 0 }, { scaleX: 1 }); */
    tl.fromTo(
      ".timeline-1 .timeline-panel",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, ease: "power2.out" }
    );
    tl.fromTo(".timeline-2 .timeline-badge", { scale: 0 }, { scale: 1 });
    /* tl.fromTo(".timeline-2 .date", { scaleX: 0 }, { scaleX: 1 }); */
    tl.fromTo(
      ".timeline-2 .timeline-panel",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, ease: "power2.out" }
    );
    tl.fromTo(".timeline-3 .timeline-badge", { scale: 0 }, { scale: 1 });
    /* tl.fromTo(".timeline-3 .date", { scaleX: 0 }, { scaleX: 1 }); */
    tl.fromTo(
      ".timeline-3 .timeline-panel",
      { y: 100, opacity: 0 },
      { y: 0, opacity: 1, duration: 1, ease: "power2.out" }
    );

    tl.to(roadmap, { y: 0 }, "+=1");

    tl.fromTo(roadmap, { y: 0 }, { y: -roadmapHeight });
    tl.set(roadmap, { visibility: "hidden" });

    // Join
    tl.set("#canvas_main", {
      bottom: "30px",
      left: "50%",
      translateX: "-50%",
      translateY: "0",
      duration: 2,
    });
    tl.set(join, { visibility: "visible" });
    tl.to(join, { y: 0 }, "+=5");
    tl.fromTo(join, { opacity: 0 }, { opacity: 1 });

    return () => {
      window.removeEventListener("resize", handleResize);
      ScrollTrigger.getAll().forEach((t) => t.kill());
    };
  }, [frameCount]);

  return (
    <>
      <Navbar />
      <div className="content">
        <div id="home_mobile">
          <HeroSection />
          <VisionSection />
          <InfoSection />
          <UtilitySection />
          <EcoSection />
          <RoadmapSection />
          <JoinSection isMobile={true} />
          <WalletConnectButton className="mobile-connect" />
          <canvas id="canvas_main" ref={canvasRef} />
        </div>
      </div>
    </>
  );
};

export default HomeMobile;
