import rexlaAxios from "./api";
import showSwal from "../utils/showSwal";

export const getPayTokens = async () => {
  try {
    const response = await rexlaAxios.post("getPayTokens");
    if (response.data && response.data.success === true) {
      return response.data.payTokens;
    }
    return;
  } catch (err) {
    showSwal(err?.response?.data?.message || err.message, "error");
    return;
  }
};
