import React, { useEffect } from "react";
import "./mission.css";
import item_1 from "../../../assets/images/landing/about-item-1.png";
import item_2 from "../../../assets/images/landing/about-item-2.png";
import item_3 from "../../../assets/images/landing/about-item-3.png";
import item_4 from "../../../assets/images/landing/about-item-4.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);

const Mission = () => {
  useEffect(() => {
    // Split text for animations
    const splitTitle = new SplitType(".mission-title", {
      types: "words, chars",
    });
    const splitDarkText = new SplitType(".text-dark", { types: "words" });

    // Main animation timeline for the mission section
    const missionTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".mission",
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none reverse",
      },
    });

    // Animate text-dark content
    missionTl.fromTo(
      splitDarkText.words,
      { opacity: 0, y: 30 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        stagger: 0.1,
        ease: "power3.out",
      },
      "-=0.5"
    );

    // Animate images and titles in mission-items
    const missionItemsTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".mission-items",
        start: "top 90%",
        toggleActions: "play none none reverse",
      },
    });

    missionItemsTl
      .fromTo(
        ".mission-description",
        { opacity: 0, scale: 0.8, y: 20 },
        {
          opacity: 1,
          scale: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          ease: "power3.out",
        }
      )
      .fromTo(
        ".mission-description span",
        { color: "#000", y: 20 },
        {
          color: "#1172d4",
          y: 0,
          duration: 1,
          stagger: 0.2,
          ease: "power3.out",
        }
      )
      .fromTo(
        ".mission-items .img-fluid",
        { opacity: 0, scale: 0.8, y: 20 },
        {
          opacity: 1,
          scale: 1,
          y: 0,
          duration: 1,
          stagger: 0.2,
          ease: "power3.out",
        }
      )
      .fromTo(
        ".mission-items h4",
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.8,
          stagger: 0.2,
          ease: "power3.out",
        },
        "-=0.5"
      )
      .fromTo(
        splitTitle.chars,
        { opacity: 0, scale: 0.5, y: 20 },
        {
          opacity: 1,
          scale: 1,
          y: 0,
          duration: 1,
          stagger: 0.05,
          ease: "power3.out",
        },
        "-=0.8"
      );

    // Clean up animations on component unmount
    return () => {
      missionTl.kill();
      missionItemsTl.kill();
    };
  }, []);
  return (
    <div className="mission">
      <div className="container">
        <h3 className="mb-5 mission-description">
          At <span>Rexla Global,</span> our mission is to empower individuals
          and businesses worldwide by making advanced technologies accessible,
          fostering positive adoption, and shaping a brighter future through
          empathy, education, and inclusive solutions.
        </h3>
        <div className="row mission-items">
          <div className="col-lg-3 col-6">
            <img src={item_1} alt="Empathy" className="img-fluid" />
            <h4>Empathy</h4>
          </div>
          <div className="col-lg-3 col-6">
            <img src={item_2} alt="Education" className="img-fluid" />
            <h4>Education</h4>
          </div>
          <div className="col-lg-3 col-6">
            <img src={item_3} alt="Compliance" className="img-fluid" />
            <h4>Compliance</h4>
          </div>
          <div className="col-lg-3 col-6">
            <img src={item_4} alt="Positive Change" className="img-fluid" />
            <h4>Positive Change</h4>
          </div>
        </div>

        <h3 className="text-dark mt-5">
          We are committed to creating a sustainable approach to compliance and
          a positive regulatory framework to protect and empower our users.
        </h3>

        <h1 className="mission-title text-start mt-4">
          Everything you need, <span>in one place</span>
        </h1>
      </div>
    </div>
  );
};
export default Mission;
