const HeroSection = () => {
  return (
    <div className="section" id="hero">
      <div className="hero-block">
        <h1 className="hero-title">Welcome to the Future</h1>
        <p className="mt-3 hero-text">
          At Rexla, we are reshaping how blockchain integrates into your
          everyday life.
          <br />
          The RXG token isn’t just a cryptocurrency—It’s the heartbeat of a
          decentralized platform designed to empower you.
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
