import { useEffect } from "react";
import Slider from "react-slick";
import "./bullets.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";
gsap.registerPlugin(ScrollTrigger);

const items = [
  {
    title: "Connect and Share",
    content:
      "Stay connected with friends and communities through our integrated social platform.",
    src: "./assets/images/bullets/about-slider-1.png",
  },
  {
    title: "Manage Finances",
    content: "Securely handle finances and trade digital assets.",
    src: "./assets/images/bullets/about-slider-2.png",
  },
  {
    title: "Learn and Grow",
    content: "Access prioritised education on the latest technologies.",
    src: "./assets/images/bullets/about-slider-3.png",
  },
  {
    title: "Protect Your Assets",
    content: "Ensure the security and future planning of your digital wealth.",
    src: "./assets/images/bullets/about-slider-4.png",
  },
  {
    title: "Shop and Travel",
    content:
      "Enjoy tailored shopping experiences or plan vacations within the platform.",
    src: "./assets/images/bullets/about-slider-5.png",
  },
  {
    title: "Make an Impact",
    content: "Support global initiatives and contribute to positive change.",
    src: "./assets/images/bullets/about-slider-6.png",
  },
];
const Bullets = () => {
  const settings = {
    dots: true,
    /* autoplay: true, */
    arrows: false,
    className: "center",
    centerMode: true,
    infinite: true,
    autoplaySpeed: 5000,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    // Split the heading text into individual words
    const headingText = new SplitType(".everyone h1", {
      types: "words, chars",
    });

    // Create a timeline
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".bullets",
        start: "top 60%",
        toggleActions: "play none none reverse",
      },
    });

    // Add animations to the timeline
    tl.fromTo(
      headingText.words,
      { y: 50, opacity: 0, scale: 0.95 },
      {
        y: 0,
        opacity: 1,
        scale: 1,
        duration: 1.2,
        ease: "power3.out",
        stagger: 0.2,
      }
    ).fromTo(
      ".bullets .slick-slide > div",
      { scale: 0.8, opacity: 0 },
      {
        scale: 1,
        opacity: 1,
        duration: 1.5,
        ease: "power3.out",
        stagger: 0.3,
      },
      "-=1" // Start 1 second before the previous animation ends
    );

    // Clean up function
    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div className="bullets">
      <div className="container">
        <div className="slider-container">
          <Slider {...settings}>
            {items.map((item, key) => (
              <div key={key} className={`slider-${key}`}>
                <img src={item.src} alt={item.title} />
                <div className="slider-contetnt">
                  <h2 className="slider-title">{item.title}</h2>
                  <p>{item.content}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Bullets;
