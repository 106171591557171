import React, { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import { Link, useNavigate } from "react-router-dom";
import { useMagic } from "../../hooks/MagicProvider";
// Assuming these imports are correct in your project structure
import rexla_icon from "../../assets/images/hub/rexla-bg.png";
import rexla_letter from "../../assets/images/hub/rexla.png";
import rexla_logo from "../../assets/images/hub/logo.png";

import user_icon from "../../assets/images/hub/user.png";
import "./hub.css";

const items = [
  { name: "Rexla", image: "./assets/images/access/Rex.png" },
  { name: "USocial", image: "./assets/images/access/uSocial@4x.png" },
  { name: "H2X", image: "./assets/images/access/h2x@4x.png" },
  { name: "Protect", image: "./assets/images/access/RProtect@4x.png" },
  { name: "Academy", image: "./assets/images/access/Guild-01.png" },
  { name: "Exchange", image: "./assets/images/access/REXCHANGE.png" },
  { name: "RXG", image: "./assets/images/access/RXG.png" },
  { name: "Wallet", image: "./assets/images/access/RWalletv1@3x.png" },
];

const Hub = () => {
  const navigate = useNavigate();
  const { isLoggedIn, userInfo } = useMagic();
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);

  const rotateLeft = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const rotateRight = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current - touchEndX.current > 75) {
      rotateRight();
    } else if (touchEndX.current - touchStartX.current > 75) {
      rotateLeft();
    }
    touchStartX.current = 0;
    touchEndX.current = 0;
  };

  const getItemStyle = (index) => {
    const positions = [
      { left: "50%", bottom: "20%" },
      { left: "80%", bottom: "10%" },
      { left: "65%", bottom: "30%" },
      { left: "50%", bottom: "20%" },
      { left: "50%", bottom: "20%" },
      { left: "50%", bottom: "20%" },
      { left: "35%", bottom: "30%" },
      { left: "20%", bottom: "10%" },
    ];

    const opacities = [1, 0.8, 0.3, 0, 0, 0, 0.3, 0.8];

    const rotatedIndex = (index - currentIndex + items.length) % items.length;
    const position = positions[rotatedIndex];

    return {
      position: "absolute",
      transition: "all 0.5s ease-in-out",
      transform:
        rotatedIndex === 0
          ? "translateX(-50%) scale(2.1)"
          : "translateX(-50%) scale(1)",
      zIndex: rotatedIndex === 0 ? 10 : 1,
      opacity: opacities[rotatedIndex],
      ...position,
    };
  };

  useEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(
      ".hub .icon-area",
      { left: "73%", top: "-85px", translateY: "0" },
      {
        left: "50%",
        translateY: "-32vh",
        duration: 1,
        delay: 0.5,
      },
      "+=0.5"
    )
      .fromTo(
        ".hub .shadow-block",
        { boxShadow: "none" },
        {
          boxShadow:
            "#82bef982 0px 0px 60px 13px, #67acef80 0px 0px 30px inset",
          duration: 2,
        }
      )
      .fromTo(
        ".hub .rexla-icon",
        {
          filter: "none",
        },
        {
          filter: "none",
          duration: 2,
        },
        "-=2"
      )
      .fromTo(
        ".hub .spotlight",
        { opacity: 0 },
        {
          visibility: "visible",
          opacity: 1,
          duration: 2,
        },
        "-=2"
      )
      .fromTo(
        ".hub .hub-btn",
        { opacity: 0 },
        {
          opacity: 1,
          duration: 2,
        },
        "-=2"
      )
      .fromTo(
        ".hub .slider-box",
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
        }
      )
      .fromTo(
        ".hub .slider-btns",
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
        },
        "-=1"
      )
      .fromTo(
        ".hub .left-btn",
        { x: 15 },
        {
          x: 0,
          duration: 1,
        },
        "-=1"
      )
      .fromTo(
        ".hub .right-btn",
        { x: -15 },
        {
          x: 0,
          duration: 1,
        },
        "-=1"
      )
      .fromTo(
        ".hub .rexla-logo",
        { y: 0 },
        {
          y: "60vh",
          duration: 2.5,
          delay: 0.5,
        },
        "-=4.5"
      )
      .fromTo(
        ".hub .profile-btn",
        { opacity: 0 },
        {
          opacity: 1,
          zIndex: 1,
          duration: 2,
        },
        "-=2"
      );

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div className="hub">
      <div className="hub-slider">
        {isLoggedIn && userInfo && (
          <button className="btn profile-btn" onClick={() => navigate("/user")}>
            <img src={user_icon} alt="user icon" />
          </button>
        )}
        <div className="logo-area">
          <div className="position-relative">
            <img src={rexla_logo} alt="logo" className="rexla-logo" />
            <div className="icon-area">
              <div className="shadow-block" />
              <img src={rexla_icon} alt="rexla icon" className="rexla-icon" />
              <img
                src={rexla_letter}
                alt="rexla icon"
                className="rexla-symbol"
              />
            </div>
          </div>
        </div>

        <div className="spotlight"></div>
        <div
          className="slider-box"
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {items.map((item, index) => (
            <div
              className={`slider-item ${
                index === currentIndex ? "active" : ""
              }`}
              key={index}
              style={getItemStyle(index)}
            >
              <img
                src={item.image}
                alt={item.name}
                style={{
                  width: "80px",
                  height: "80px",
                  objectFit: "contain",
                }}
              />
            </div>
          ))}
        </div>
        <div className="button-block">
          <div className="slider-btns">
            <button
              className="btn btn-prmary rounded-pill px-4 text-white left-btn"
              onClick={rotateLeft}
            >
              <i className="me-2 fa fa-chevron-left" />
            </button>
            <span className="text-white">{items[currentIndex].name}</span>
            <button
              className="btn btn-prmary rounded-pill px-4 text-white right-btn"
              onClick={rotateRight}
            >
              <i className="ms-2 fa fa-chevron-right" />
            </button>
          </div>
          <div>
            <Link className="btn hub-btn" to="/ico" target="_blank">
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hub;
