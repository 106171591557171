// import discount_ico from "../../../assets/images/discount.png";
// import reward_ico from "../../../assets/images/reward.png";
// import gover_ico from "../../../assets/images/governance.png";
import "./utility.css";

const UtilitySection = () => {
  return (
    <div className="section text-start d-flex align-items-center" id="utility">
      <div className="utility-container d-flex">
        <div className="utility-box position-relative">
          <div>
            <div className="utility-1">
              {/* <h5>TOKEN</h5> */}
              <h1>Why Choose RXG?</h1>
              <p>
                1. Fixed Supply: A total supply of 10 billion RXG tokens ensures
                scarcity and long-term value.
              </p>
              <p>
                2. Revenue-Driven Buybacks: Part of platform revenue is
                reinvested to repurchase RXG tokens, reducing supply and
                enhancing value.
              </p>
              <p>
                3. Platform Integration: RXG powers the entire Rexla Continuum,
                including the Exchange, Wallet, Academy, and Social platform.
              </p>
              <p>
                4. Sustainable Rewards: A perpetual reward pool ensures fair and
                lasting incentives for loyal users.
              </p>

              <div className="utility-2 mt-2">
                <p>With RXG, you get access to:</p>
                <p>• Cutting-edge tools</p>
                <p>• Unmatched</p>
                <p>• security</p>
                <p>• Effortless usability</p>
                <p>All powered by blockchain</p>
              </div>
            </div>
            {/* <div className="utility-2">
              <button className="btn btn-primary mt-4">REXLA ECOSYSTEM</button>
            </div> */}
          </div>
        </div>
        <div className="benefit-box position-relative">
          <div>
            <div className="utility-1">
              {/* <h5>TOKEN</h5> */}
              <h1>What is RXG?</h1>
              {/* <h6>RXG Token: The Catalyst for Change</h6> */}
              <p>
                The RXG token is the cornerstone of the Rexla Continuum,
                offering real-world utility across our diverse suite of
                services. With RXG, you unlock:
              </p>
            </div>
            <div className="utility-2 mt-4">
              {/* <h6>Our Mission</h6> */}
              <p>• Discounted Fees: Save up to 40% on platform fees.</p>
              <p>
                • Exclusive Staking Rewards: Earn loyalty points and access
                premium features.
              </p>
              <p>
                • Seamless Payments: Use RXG for services across the Rexla
                ecosystem.
              </p>
              <p>
                • Governance Power: Shape the future of Rexla with your vote.
              </p>
              {/* <div className="d-flex align-items-center gap-4 mb-3 mt-4">
                <img src={discount_ico} alt="discount" />
                <div>
                  <h6>Discounts</h6>
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4 mb-3">
                <img src={reward_ico} alt="reward" />
                <div>
                  <h6>Staking Rewards</h6>
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-4">
                <img src={gover_ico} alt="governance" />
                <div>
                  <h6>Governance Participation</h6>
                  <p className="mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UtilitySection;
