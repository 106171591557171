import { Navbar } from "../../components/ProfileNavbar.js";
import avatar from "../../assets/images/profile/RXG.png";
import card_logo from "../../assets/images/profile/card-logo.png";
import user_ico from "../../assets/images/profile/profile-ico.png";
import kyc_ico from "../../assets/images/profile/kyc-ico.png";
import wallet_ico from "../../assets/images/profile/wallet-ico.png";
import AccountComp from "../../components/profile/account.js";
import SecurityComp from "../../components/profile/security.js";
import WalletComp from "../../components/profile/wallet.js";
import EducationComp from "../../components/profile/education";
import PrivacyComp from "../../components/profile/privacy.js";
import SettingsComp from "../../components/profile/settings.js";
import "./profile.css";
import { useMagic } from "../../hooks/MagicProvider.js";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "../../components/loading";

const Profile = () => {
  const { isLoading, isLoggedIn, userInfo, shortAddress } = useMagic();
  const navigate = useNavigate();
  const [copyStatus, setCopyStatus] = useState(false);

  const [isProfile, setIsProfile] = useState(true);
  const [isKyc, setIsKyc] = useState(false);
  const [isWallet, setIsWallet] = useState(true);

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [isClickedSave, setIsClickedSave] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    if (!isLoading && !isLoggedIn) navigate("/onboarding");
  }, [isLoggedIn]);

  useEffect(() => {
    if (userInfo) {
      setIsKyc(userInfo?.isVerified);
    }
  }, [userInfo]);

  const handleCopy = async (copyText) => {
    try {
      await navigator.clipboard.writeText(copyText);
      setCopyStatus(true);
    } catch (error) {
      setCopyStatus(false);
    }
  };
  if (isLoading) return <LoadingScreen />;
  return (
    <div className="profile">
      <div className="profile-header">
        <Navbar />
        <div className="container">
          <h2 className="mt-5 text-white">
            Welcome back,{" "}
            <span>
              {userInfo?.first_name}
              {` `}
              {userInfo?.last_name}
            </span>
          </h2>
          <div className="card profile-card m-auto mt-5">
            <div className="card-body d-flex align-items-center">
              {/* Image with rounded style */}
              <div className="image-container">
                <img
                  src={avatar}
                  alt="User"
                  className="rounded-circle profile-image img-fluid"
                />
              </div>

              {/* Wallet address and copy button */}
              <div className="ms-3 text-end flex-fill">
                <img src={card_logo} alt="card-logo" className="card-logo" />
                <p className="wallet-text mt-4">Public wallet address</p>
                <h3 className="wallet-address text-white mt-1">
                  {shortAddress}
                </h3>
                <button
                  className="btn copy-button mt-3"
                  disabled={isLoading || !userInfo}
                  onClick={() => handleCopy(userInfo?.address)}
                >
                  {copyStatus ? (
                    <>
                      <i
                        className="fa fa-check"
                        style={{ cursor: "pointer" }}
                      />{" "}
                      Copied!
                    </>
                  ) : (
                    <>
                      <i className="far fa-copy"></i> Copy
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profile-progress">
        <div className="progress-container">
          <div className={`progress-item ${isProfile ? `active` : `disable`}`}>
            <div>
              {/* <i className="far fa-user" /> */}
              <img src={user_ico} alt="user-icon" />
              <div className="check-box">
                <i className={`fa ${isProfile ? `fa-check` : `fa-minus`}`} />
              </div>
            </div>
            <span>Profile&nbsp;Setup</span>
          </div>
          <div className={`progress-item ${isKyc ? `active` : `disable`}`}>
            <div>
              <img src={kyc_ico} alt="kyc-icon" />
              <div className="check-box">
                <i className={`fa ${isKyc ? `fa-check` : `fa-minus`}`} />
              </div>
            </div>
            <span>KYC</span>
          </div>
          <div className={`progress-item ${isWallet ? `active` : `disable`}`}>
            <div>
              {/* <i className="fa fa-wallet" /> */}
              <img src={wallet_ico} alt="wallet-icon" />
              <div className="check-box">
                <i className={`fa ${isWallet ? `fa-check` : `fa-minus`}`} />
              </div>
            </div>
            <span>Wallet</span>
          </div>
        </div>
      </div>

      <div className="profile-container">
        <ul className="nav justify-content-center mb-3">
          <li className="nav-item">
            <a
              className="nav-link active"
              data-bs-toggle="pill"
              data-bs-target="#pills-account"
              aria-controls="pills-account"
              aria-selected="true"
              type="button"
            >
              Account
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="pill"
              data-bs-target="#pills-security"
              aria-controls="pills-security"
              aria-selected="false"
              type="button"
            >
              Security
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="pill"
              data-bs-target="#pills-wallet"
              aria-controls="pills-wallet"
              aria-selected="false"
              type="button"
            >
              Wallet
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="pill"
              data-bs-target="#pills-education"
              aria-controls="pills-education"
              aria-selected="false"
              type="button"
            >
              Education
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="pill"
              data-bs-target="#pills-privacy"
              aria-controls="pills-privacy"
              aria-selected="false"
              type="button"
            >
              Privacy
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-bs-toggle="pill"
              data-bs-target="#pills-settings"
              aria-controls="pills-settings"
              aria-selected="false"
              type="button"
            >
              Settings
            </a>
          </li>
        </ul>
        <div className="tab-content pt-1" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-account">
            <AccountComp
              isSaveEnabled={isSaveEnabled}
              setIsSaveEnabled={setIsSaveEnabled}
              isClickedSave={isClickedSave}
              setIsClickedSave={setIsClickedSave}
              updateLoading={updateLoading}
              setUpdateLoading={setUpdateLoading}
            />
          </div>
          <div className="tab-pane fade" id="pills-security">
            <SecurityComp />
          </div>
          <div className="tab-pane fade" id="pills-wallet">
            <WalletComp />
          </div>
          <div className="tab-pane fade" id="pills-education">
            <EducationComp />
          </div>
          <div className="tab-pane fade" id="pills-privacy">
            <PrivacyComp />
          </div>
          <div className="tab-pane fade" id="pills-settings">
            <SettingsComp />
          </div>
        </div>
      </div>
      {isSaveEnabled && (
        <div className="profile-bottom">
          <button
            onClick={() => setIsSaveEnabled(false)}
            className="btn-close"
          ></button>
          <button
            className="btn btn-save d-flex align-items-center gap-2"
            onClick={() => setIsClickedSave(true)}
            disabled={updateLoading}
          >
            {updateLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Save changes
          </button>
        </div>
      )}
    </div>
  );
};

export default Profile;
