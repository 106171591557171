import { useNavigate } from "react-router-dom";
import wallet from "../../../assets/images/ecosystem/wallet.png";
import social from "../../../assets/images/ecosystem/social.png";
import h2x from "../../../assets/images/ecosystem/h2x.png";
import exchange from "../../../assets/images/ecosystem/exchange.png";
import guild from "../../../assets/images/ecosystem/guild.png";
import "./ecosystem.css";

const EcoSection = () => {
  const navigate = useNavigate();
  return (
    <div className="section d-flex flex-column" id="ecosystem">
      <div className="ecosystem-container text-white d-flex">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 text-start">
              <h1 className="utility-title">Utility Token</h1>
              <p className="utility-subtitle">
                With RXG, you get access to a world where blockchain works
                <br />
                behind the scenes to make life simpler, smarter, and safer.
              </p>
            </div>
            <div className="col-lg-6 text-start d-flex align-items-center gap-5">
              <h1 className="mint-title">10</h1>
              <div className="ml-3 w-100">
                <h3 className="Jeko-light ms-4">
                  Billion RXG tokens will be minted
                </h3>
                <button className="btn btn-outline-primary mt-2">
                  No new tokens created in the future
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="eco-sub-block container flex-fill d-flex align-items-center flex-column justify-content-center gap-3">
        <div className="ecosystem-title-block position-relative text-center">
          <h5 className="ecosystem-fade">REXLA</h5>
          <h1 className="ecosystem-title">The Continuum</h1>
          <p className="ecosystem-subtitle ecosystem-fade">
            Experience the Rexla Continuum
          </p>
        </div>
        <div className="d-flex flex-row">
          <div className="text-center fade-1">
            <img src={exchange} alt="exchange" />
            <h4>REXLA EXCHANGE</h4>
            {/* <h3>Trading Platform</h3> */}
            <p>
              A low-cost, secure platform to trade, swap, and manage digital
              assets.
            </p>
          </div>
          <div className="text-center fade-2">
            <img src={wallet} alt="wallet" />
            <h4 className="text-white">REXLA WALLET & PROTECT</h4>
            {/* <h3>Secure Digital Wallet</h3> */}
            <p>
              Manage your crypto safely and designate guardians for asset
              recovery.
            </p>
          </div>
          <div className="text-center fade-3">
            <img src={guild} alt="guild" />
            <h4>REXLA ACADEMY</h4>
            {/* <h3>Educational Hub</h3> */}
            <p>Advance your Web3 knowledge with world-class education.</p>
          </div>

          <div className="text-center fade-4">
            <img src={h2x} alt="h2x" />
            <h4>H2X WATER</h4>
            {/* <h3>Blockchain Assurance</h3> */}
            <p>
              Enjoy blockchain-tracked premium bottled water with a commitment
              to sustainability.
            </p>
          </div>

          <div className="text-center fade-5">
            <img src={social} alt="h2x" />
            <h4>REXLA SOCIAL</h4>
            {/* <h3>Blockchain Assurance</h3> */}
            <p>
              Connect, share, and thrive in a crypto-focused social media
              platform.
            </p>
          </div>
        </div>
        <div className="text-center w-100 mt-5">
          <button
            className="btn btn-primary btn-lg"
            onClick={() => {
              navigate("/ico");
            }}
          >
            GET RXG TOKEN
          </button>
        </div>
      </div>
    </div>
  );
};

export default EcoSection;
