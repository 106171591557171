import { useMagic } from "../hooks/MagicProvider";

const WalletConnectButton = ({ className, text }) => {
  const { handleConnectWallet, shortAddress, isLoading, isLoggedIn } =
    useMagic();
  return (
    <button
      className={className}
      onClick={() => handleConnectWallet()}
      disabled={isLoading}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px",
      }}
    >
      {isLoading ? (
        <>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </>
      ) : isLoggedIn ? (
        shortAddress
      ) : (
        text || "Connect\u00A0Wallet"
      )}
    </button>
  );
};
export default WalletConnectButton;
