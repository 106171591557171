import rexlaAxios from "./api";
import showSwal from "../utils/showSwal";

export const updateUser = async ({ short_code, info }) => {
  try {
    const response = await rexlaAxios.post("updateUser", {
      short_code,
      info,
    });
    if (response.data && response.data.success === true) {
      return response.data;
    }
    return;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};

export const checkUser = async (userMetadata) => {
  try {
    const response = await rexlaAxios.post("checkUser", {
      address: userMetadata.publicAddress,
    });
    if (response.data && response.data.success === true) {
      return response.data.data;
    }
    return;
  } catch (err) {
    showSwal(err.message, "error");
    return;
  }
};
