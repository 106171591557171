import React, { useEffect, useState } from "react";
import "./form.css"; // Custom CSS file for styling
import { useMagic } from "../../hooks/MagicProvider";
import { updateUser } from "../../api";
import showSwal from "../../utils/showSwal";

const AccountComp = ({
  isSaveEnabled,
  setIsSaveEnabled,
  isClickedSave,
  setIsClickedSave,
  updateLoading,
  setUpdateLoading,
}) => {
  const { userInfo } = useMagic();

  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    birth: "",
    address: "",
    city: "",
    state: "",
  });

  const [phoneValid, setPhoneValid] = useState(true); // For validation example
  const [showPhoneError, setShowPhoneError] = useState(false);

  useEffect(() => {
    if (userInfo) {
      const data = userInfo.info ? JSON.parse(userInfo.info) : formData;
      setFormData({
        ...data,
        fullName: userInfo?.first_name + " " + userInfo?.last_name,
        email: userInfo?.email || "",
        state: userInfo?.residence_country || "",
      });
    }
  }, [userInfo]);

  const handleInputChange = (e) => {
    if (updateLoading) return;
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setIsSaveEnabled(true);

    if (name === "phone") {
      const isValidPhone = /^\+?\d{10,15}$/.test(value);
      setPhoneValid(isValidPhone);
      setShowPhoneError(!isValidPhone);
    }
  };

  const handleSaveChanges = async () => {
    try {
      setUpdateLoading(true);
      const response = await updateUser({ info: JSON.stringify(formData) });

      if (response.success) {
        showSwal("Your account information has been updated.", "success");
        setIsSaveEnabled(false);
      } else {
        throw new Error("Failed to save changes");
      }
    } finally {
      setIsClickedSave(false);
      setUpdateLoading(false);
    }
  };

  useEffect(() => {
    if (isClickedSave) {
      handleSaveChanges();
    }
  }, [isClickedSave]);

  return (
    <form className="custom-form">
      {/* Full Name */}
      <div className="form-group">
        <label className="form-label">Full Name</label>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={formData.fullName}
            readOnly
            onChange={(e) =>
              setFormData({ ...formData, fullName: e.target.value })
            }
          />
          <div className="input-group-append valid">
            <i className="fa fa-check" />
          </div>
        </div>
      </div>

      {/* Email */}
      <div className="form-group">
        <label className="form-label">E-Mail</label>
        <div className="input-group">
          <input
            type="email"
            name="email"
            className="form-control"
            readOnly
            value={formData.email}
            onChange={handleInputChange}
          />
          <div className="input-group-append valid">
            <i className="fa fa-check" />
          </div>
        </div>
      </div>

      {/* Phone */}
      <div className="form-group">
        <label className="form-label">Phone</label>
        <div className="input-group">
          <input
            type="tel"
            name="phone"
            className={`form-control ${!phoneValid ? "invalid" : ""}`}
            value={formData.phone}
            onChange={handleInputChange}
          />
          <div
            className={`input-group-append ${phoneValid ? "valid" : "invalid"}`}
          >
            {phoneValid ? (
              <i className="fa fa-check" />
            ) : (
              <i className="fa fa-minus" />
            )}
          </div>
        </div>
      </div>
      {showPhoneError && (
        <small className="form-text text-danger">
          Enter a valid phone number
        </small>
      )}

      {/* Birth Date */}
      <div className="form-group">
        <label className="form-label">Birth</label>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            name="birth"
            value={formData.birth}
            onChange={handleInputChange}
          />
          <div className="input-group-append">
            <i className="fa fa-check" />
          </div>
        </div>
      </div>

      {/* Address */}
      <div className="form-group">
        <label className="form-label">Address</label>
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
          />
          <div className="input-group-append">
            <i className="fa fa-check" />
          </div>
        </div>
      </div>

      {/* City & State */}
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label className="form-label">City</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
              />
              <div className="input-group-append">
                <i className="fa fa-check" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="form-group">
            <label className="form-label">State</label>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                readOnly
                name="state"
                value={formData.state}
                onChange={handleInputChange}
              />
              <div className="input-group-append">
                <i className="fa fa-check" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AccountComp;
