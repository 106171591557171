import { OAuthExtension } from "@magic-ext/oauth";
import { ethers } from "ethers";
import { Magic as MagicBase } from "magic-sdk";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import rexlaAxios from "../api/api";

const MagicContext = createContext({
  magic: null,
  isLoggedIn: false,
  userMetadata: null,
  userInfo: null,
  provider: null,
  handleConnectWallet: async () => {},
  handleLogout: async () => {},
  shortAddress: "",
  isLoading: false,
  balance: null,
});

export const useMagic = () => useContext(MagicContext);

const MagicProvider = ({ children }) => {
  const [magic, setMagic] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userMetadata, setUserMetadata] = useState(null);
  const [shortAddress, setShortAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState(null);
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const initializeMagic = () => {
      const apiKey = process.env.REACT_APP_MAGIC_API_KEY;
      const networkId = process.env.REACT_APP_NETWORK_ID;
      if (!apiKey || !networkId) {
        console.error("Missing Magic API Key or Network ID");
        return;
      }

      const magicInstance = new MagicBase(apiKey, {
        network: {
          rpcUrl: "https://sepolia.base.org",
          //rpcUrl: "https://mainnet.base.org",
          chainId: networkId,
        },
        extensions: [new OAuthExtension()],
      });

      setMagic(magicInstance);
      setProvider(new ethers.providers.Web3Provider(magicInstance.rpcProvider));
    };

    initializeMagic();
  }, []);

  const shortenAddress = (address) =>
    address ? `${address.slice(0, 6)}...${address.slice(-4)}` : "";

  const checkUserLoggedIn = async () => {
    if (magic) {
      setIsLoading(true);
      try {
        // Check if the user is logged in via Magic SDK
        const loggedIn = await magic.user.isLoggedIn();
        setIsLoggedIn(loggedIn);

        if (loggedIn) {
          const metadata = await magic.user.getInfo();
          setUserMetadata(metadata);
          setShortAddress(shortenAddress(metadata.publicAddress));

          // Verify user session with the backend
          const idToken = await magic.user.getIdToken();
          const response = await rexlaAxios.post("validateUser", { idToken });

          if (response.data.success) {
            const userBalance = ethers.utils.formatEther(
              await provider.getBalance(metadata.publicAddress)
            );
            setBalance(userBalance);
            setUserInfo(response.data.user);
          } else {
            console.error("Session validation failed with backend");
            handleLogout();
          }
        }
      } catch (error) {
        console.error("Error checking login status:", error);
        handleLogout(); // Log out if any error occurs during session validation
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    checkUserLoggedIn();
  }, [magic]);

  const handleConnectWallet = async () => {
    if (!magic) return;

    setIsLoading(true);
    try {
      if (!isLoggedIn) {
        const accounts = await magic.wallet.connectWithUI();
        const idToken = await magic.user.getIdToken();

        // Send ID token to the backend for verification
        await rexlaAxios.post("login", { idToken });

        checkUserLoggedIn();

        return accounts;
      } else {
        await magic.wallet.showUI();
      }
    } catch (error) {
      console.error("Error connecting wallet:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    if (!magic) return;

    setIsLoading(true);
    try {
      await magic.user.logout();
      setIsLoggedIn(false);
      setUserMetadata(null);
      setShortAddress("");
      setBalance(null);
    } catch (error) {
      console.error("Error during logout:", error);
    } finally {
      setIsLoading(false);
    }
  };

  async function getTokenBalance(tokenAddress, walletAddress) {
    try {
      // ERC20 ABI with `balanceOf` and `decimals` functions
      const ERC20_ABI = [
        "function balanceOf(address account) external view returns (uint256)",
        "function decimals() external view returns (uint8)",
      ];

      // Connect to the token contract
      const tokenContract = new ethers.Contract(
        tokenAddress,
        ERC20_ABI,
        provider
      );

      // Fetch the raw balance and token decimals
      const rawBalance = await tokenContract.balanceOf(walletAddress);
      const decimals = await tokenContract.decimals();

      // Format the balance to a human-readable value
      const formattedBalance = ethers.utils.formatUnits(rawBalance, decimals);
      return formattedBalance;
    } catch (error) {
      console.error("Error fetching token balance:", error);
      throw error; // Optionally handle errors more gracefully
    }
  }

  const value = useMemo(
    () => ({
      magic,
      isLoggedIn,
      userMetadata,
      provider,
      handleConnectWallet,
      handleLogout,
      userInfo,
      shortAddress,
      isLoading,
      balance,
      getTokenBalance,
      checkUserLoggedIn,
    }),
    [
      magic,
      isLoggedIn,
      userMetadata,
      provider,
      shortAddress,
      isLoading,
      balance,
    ]
  );

  return (
    <MagicContext.Provider value={value}>{children}</MagicContext.Provider>
  );
};

export default MagicProvider;
