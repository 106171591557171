import rexlaAxios from "./api";
import showSwal from "../utils/showSwal";

export const checkBuyable = async () => {
  try {
    const response = await rexlaAxios.post("checkBuyable");
    if (response.data) {
      return response.data;
    }
    return;
  } catch (err) {
    showSwal(err?.response?.data?.message || err.message, "error");
    return;
  }
};

export const getBuyData = async ({ count, payTokenAddress }) => {
  try {
    const response = await rexlaAxios.post("getBuyData", {
      count,
      payTokenAddress,
    });
    if (response.data) {
      return response.data;
    }
    return;
  } catch (err) {
    showSwal(err?.response?.data?.message || err.message, "error");
    return;
  }
};
